import ConstantTool from "@/services/tool/ConstantTool";
import axios from "axios";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import JsonTool from "@/services/tool/JsonTool";
import Headquarter from "@/models/Headquarter";
import Response from "@/models/responses/Response";
import SnackbarModule from "@/store/SnackbarModule";

export default class HeadquarterService {

    static async getById(id: number): Promise<Response<Headquarter>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + `/api/headquarters/${id}`, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const headquarter = JsonTool.jsonConvert.deserializeObject(response.data, Headquarter)
            return Promise.resolve({ result: headquarter })
        } catch (e) {
            return Promise.reject(e)
        }
    }

    static async findAll(page: number, size: number) {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + `/api/headquarters`, {
                params: { page, size },
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const headquarter = JsonTool.jsonConvert.deserializeArray(response.data, Headquarter)
            return Promise.resolve({ result: headquarter })
        } catch (e) {
            return Promise.reject(e)
        }
    }

    static async create(request: Headquarter): Promise<Response<Headquarter>> {
        try {
            const response = await axios.post(ConstantTool.BASE_URL + `/api/headquarters`, request, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const headquarter = JsonTool.jsonConvert.deserializeObject(response.data, Headquarter)
            return Promise.resolve({ result: headquarter })
        } catch (e) {
            return Promise.reject(e)
        }
    }

    static async update(id: number, request: Headquarter): Promise<Response<Headquarter>> {
        try {
            const response = await axios.put(ConstantTool.BASE_URL + `/api/headquarters/${id}`, request, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const headquarter = JsonTool.jsonConvert.deserializeObject(response.data, Headquarter)
            return Promise.resolve({ result: headquarter })
        } catch (e) {
            return Promise.reject(e)
        }
    }

    static async delete(id: number) {
        try {
            const response = await axios.delete(ConstantTool.BASE_URL + `/api/headquarters/${id}`, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            if (response.status == 200) { getModule(SnackbarModule).makeToast("La sede se eliminó exitosamente.") }
            return Promise.resolve({ result: null })
        } catch (e) {
            return Promise.reject(e)
        }
    }

}