







































































import {Vue, Component} from 'vue-property-decorator'
import {getModule} from "vuex-module-decorators"
import LangModule from "@/store/LangModule"
import Rules from "@/services/tool/Rules";
import Headquarter from "@/models/Headquarter";
import {SingleItem} from "@/handlers/interfaces/ContentUI";
import Handler from "@/handlers/Handler";
import HeadquarterService from "@/services/HeadquarterService";
import SnackbarModule from "@/store/SnackbarModule";

@Component({})
export default class CreateHeadquarterDialog extends Vue {

    get lang() { return getModule(LangModule).lang }
    get rules() { return Rules }

    loading: boolean = false
    headquarter: SingleItem<Headquarter> = { item: new Headquarter()}

    async createHeadquarter() {
      try {
        await Handler.getItem(this, this.headquarter, () => HeadquarterService.create(this.headquarter.item))
        getModule(SnackbarModule).makeToast(this.lang.headquarterSuccessfullyCreated)
      } catch (e) {
        getModule(SnackbarModule).makeToast(this.lang.errorOccurred)
      }
      this.$emit("afterCreated", this.headquarter.item)
      this.$emit("close")
    }

}
